const sanisettes = require("../geojson/sanisettesparis.geojson");
const fontaines = require("../geojson/fontaines-a-boire.geojson");
let map = L.map('map')
    .setView([48.86, 2.34], 13)
    .locate({setView: true, maxZoom: 16});

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18
    })
    .addTo(map);

function addGeoJSON(path, color) {

    var oReq = new XMLHttpRequest();
    oReq.onload = reqListener;
    oReq.open("get", path, true);
    oReq.send();

    function reqListener(e) {

        var geojsonFeature = JSON.parse(this.responseText);

        L.geoJSON(geojsonFeature, {
            pointToLayer: function (feature, latlng) {
                if (
                    feature.properties.hasOwnProperty("a_boire") &&
                    feature.properties.hasOwnProperty("modele")
                ) {
                    if (
                        feature.properties.a_boire &&
                        feature.properties.modele !== "Sanisette"
                    ) {
                        return new L.CircleMarker(latlng, {
                            fillColor: color,
                            radius: 7,
                            fillOpacity: 0.8,
                            stroke: false,
                            interactive: false
                        });
                    }
                } else {
                    return new L.CircleMarker(latlng, {
                        fillColor: color,
                        radius: 7,
                        fillOpacity: 0.8,
                        stroke: false,
                        interactive: false
                    });
                }
            }
        }).addTo(map);

    }

}

addGeoJSON(sanisettes, "#293845");
addGeoJSON(fontaines, "#1685ED");